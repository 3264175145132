import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/components/Button/Button';
import HeadingText from '../../../shared/components/HeadingText/HeadingText';
import CheckIcon from '../../../shared/components/Icons/CheckIcon';
import JobHeader from '../../../shared/components/JobHeader/JobHeader';
import { getCurrentStep, getSummarySteps } from '../../../store/exam/selectors';
import { useAppSelector } from '../../../store/store';
import { getSummaryLoading } from '../../../store/ui/selectors';
import AskPermissions from './AskPermissions';
import ExamProgram from './ExamProgram';

interface Props {
  hasPermissions: boolean;
  permissionsDenied: boolean;
  permissionsError: boolean;
  onRequirePermissions: () => void;
  onSubmit: () => void;
}

const SummaryBox = ({
  hasPermissions,
  permissionsDenied,
  permissionsError,
  onRequirePermissions,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();

  const loading = useAppSelector(getSummaryLoading);
  const summarySteps = useAppSelector(getSummarySteps);
  const currentStep = useAppSelector(getCurrentStep);

  if (!currentStep || currentStep.type !== 'summary') {
    return null;
  }

  return (
    <div
      className={clsx(
        'flex h-full w-full flex-col items-center bg-[#FFFFFF] pt-8',
        'lg:h-auto lg:justify-center lg:rounded-[8px] lg:p-6 lg:pt-4 lg:shadow-cust-1'
      )}
    >
      {/* Company */}
      <JobHeader />

      {/* Description */}
      <div className="my-4 flex w-full flex-row justify-start px-4 lg:px-0">
        <HeadingText variant="secondary">{t('summary.description')}</HeadingText>
      </div>

      {/* Exam */}
      <div className="my-4 w-full flex-col px-4 lg:px-0">
        <ExamProgram steps={summarySteps} />
      </div>

      {/* Permissions Banner */}
      {!hasPermissions && (
        <AskPermissions
          permissionsDenied={permissionsDenied}
          permissionsError={permissionsError}
          onRequirePermissions={onRequirePermissions}
        />
      )}

      {/* Next Button */}
      {hasPermissions && (
        <div
          className={clsx(
            'fixed bottom-0 z-10 flex w-full flex-col items-center border-t-[1px] border-solid border-[#D0D5DD] bg-[#FFFFFF] py-2',
            'lg:static lg:flex-row lg:justify-between lg:border-0 lg:pb-0'
          )}
        >
          <div className="flex items-center gap-4">
            <CheckIcon />
            <span className="text-[#111111]">{t('summary.authorizations.ok')}</span>
          </div>

          <Button
            loading={loading}
            disabled={!hasPermissions}
            className="default"
            onClick={onSubmit}
          >
            {t('summary.button.next')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SummaryBox;
